import React, { useEffect, useRef } from 'react'
import { tsParticles } from 'tsparticles'
import { css } from 'styled-components'

interface Props {
	update: string
}

const ParticlesBackground = ({ update }: Props) => {
	const isFirstRun = useRef(true)
	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}
		// simulate a resize event so the particles background will size accordingly
		window.dispatchEvent(new Event('resize'))
	}, [update])

	useEffect(() => {
		tsParticles.load('tsparticles', {
			fpsLimit: 60,
			particles: {
				// color: { value: ['#DEDEDE', '#EDEDED', '#EFEFEF', '#EEEEEE', '#DDDDDD'] },
				color: { value: ['#E1E5EA', '#E0E4EB', '#D0D4DB', '#D6DDE6', '#D9DDE4'] },
				opacity: {
					value: 1,
					random: false,
					anim: {
						enable: false
					}
				},
				size: {
					value: 4,
					random: {
						enable: true,
						minimumValue: 2
					}
				},
				line_linked: {
					// color: { value: '#CCC' },
					// color: { value: '#C4CCD8' },
					color: { value: ['#E1E5EA', '#E0E4EB', '#D0D4DB', '#D6DDE6', '#D9DDE4'] },
					distance: 125,
					enable: true
				},
				move: {
					enable: true,
					speed: 1.5,
					// direction: 'none',
					// random: false,
					// straight: false,
					outMode: 'out'
					// warp: true
				}
			}
		})
	}, [])

	return (
		<div
			id="tsparticles"
			css={css`
				position: absolute;
				min-width: 100%;
				min-height: 100%;

				top: -150px;
				bottom: -150px;
				right: -150px;
				left: -150px;
				z-index: -2;
			`}
		/>
	)
}

export default ParticlesBackground
